import * as React from 'react';
import { graphql } from 'gatsby';
import Header from '../common/header/header';
import Footer from '../common/footer/footer';
import BackToTopScroll from '../components/backToTopScroll/backToTopScroll';
import GlobalStyles from '../styles/globalStyles';
import {
  HtmlSiteMapHeader,
  HtmlSitemapLink,
  HtmlSitemapMainDiv,
} from '../styles';
import { addTrailingSlash } from '../utils';
import TitleWithImage from '../components/titleWithImage/titleWithImage';
import BannerImage from '../images/banner-default.png';
import { Helmet } from 'react-helmet';
import AOS from 'aos';
import 'aos/dist/aos.css';

const HTMLSiteMap = ({ data }) => {
  React.useLayoutEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  const allSlugs = [
    ...data?.allContentfulSparlings?.edges
      ?.filter((edge) => {
        return edge?.node?.slug && edge;
      })
      .map((edge) => {
        return {
          slug: addTrailingSlash(edge?.node?.slug),
          title: edge?.node?.title,
        };
      }),
    ...data?.allContentfulLocation?.edges
      ?.filter((edge) => {
        return edge?.node?.urlSlug && edge;
      })
      .map((edge) => {
        return {
          slug: addTrailingSlash('/locations/details/' + edge?.node?.urlSlug),
          title:
            edge?.node?.pageTitle ??
            edge?.node?.pageTitleDetails ??
            edge?.node?.urlSlug,
        };
      }),
  ].sort((a, b) => a?.slug?.localeCompare(b?.slug));

  const SlugList = allSlugs.filter(function (x, i, a) {
    return a.indexOf(x) === i;
  });
  return (
    <>
      <GlobalStyles />
      <Helmet>
        <title>Site Map</title>
      </Helmet>
      <Header />
      <TitleWithImage
        sectionData={{
          text: { text: 'Site Map' },
          image: { file: { url: BannerImage } },
        }}
      />
      <HtmlSitemapMainDiv>
        {SlugList.map((slug, index) => (
          <HtmlSiteMapHeader
            $leftSpace={slug?.slug?.split('/')?.length - 1}
            key={index}
          >
            <HtmlSitemapLink href={slug?.slug}>{slug.title}</HtmlSitemapLink>
          </HtmlSiteMapHeader>
        ))}
      </HtmlSitemapMainDiv>

      <Footer />
      <BackToTopScroll />
    </>
  );
};

export default HTMLSiteMap;
export const pageQuery = graphql`
  query MyQuery {
    allContentfulSparlings(
      filter: { node_locale: { eq: "en-US" }, hasIndex: { ne: false } }
    ) {
      edges {
        node {
          slug
          title
          name
          node_locale
        }
      }
    }
    allContentfulLocation(
      filter: {
        node_locale: { eq: "en-US" }
        brand: { brand: { in: ["Sparlings"] } }
      }
    ) {
      edges {
        node {
          urlSlug
          pageTitle
          pageTitleDetails
        }
      }
    }
  }
`;
